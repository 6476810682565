import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import LocalTrainingModality from '@/shared/models/localTrainingModality'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  props: {
    localTrainingsModalities: {
      type: Array,
      default: []
    }
  },
  data:() => ({
    localTrainingModality: new LocalTrainingModality(),
    waitingListLocalTrainingSelected: null,
    waitingListModalitySelected: null,
    showModalLocalTrainingModality: false,
    showModalDeleteLocalTrainingModality: false,
    emptyLocalTrainingModality: 'Não há local de treinamento ou modalidade cadastrada',
    modalityLocalTraining: [],
    modalities: [],
    listLocalTraining: [],
    localTrainingModalitySelectedIndex: null,
    fieldsTrainingAndModalits: [
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'left-header-border text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'presetClass',
        label: 'TURMA/HORÁRIO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.loadModalityLocalTraining()
  },

  methods: {
    loadModalityLocalTraining () {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    clearForm () {
      this.showModalLocalTrainingModality = false
      this.localTrainingModality = new LocalTrainingModality()
      this.onSelectModality()
      this.onSelectLocalTraining()
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    onSave () {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }
        
        this.mergeItemList()
        this.showModalLocalTrainingModality = false
        this.clearForm()
      })
    },

    onSelectLocalTraining () {
      let list = this.modalityLocalTraining

      if (this.localTrainingModality.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.localTrainingModality.localTrainingId)

        if (type.length > 0) {
          this.localTrainingModality.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality () {
      let list = this.modalityLocalTraining

      if (this.localTrainingModality.modalityId) {
        let type = this.modalities.filter(s => s.id === this.localTrainingModality.modalityId)

        if (type.length > 0) {
          this.localTrainingModality.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }
    },

    onAddLocalTrainingModality () {
      this.clearForm()
      this.localTrainingModalitySelectedIndex = null
      this.showModalLocalTrainingModality = true
    },

    onInactive () {
      this.removeItemList()
      this.showModalDeleteTrainingModality = false
      this.localTrainingModalitySelectedIndex = null
    },

    onShowModalDeleteLocalTrainingModality(index) {
      this.localTrainingModalitySelectedIndex = index
      this.showModalDeleteLocalTrainingModality = true
    },

    removeItemList () {
      const index = this.localTrainingModalitySelectedIndex
      const hasElement = this.localTrainingsModalities[index] != null

      if (!hasElement) {
        toast.error('Não foi possível excluir o local de treinamento/modalidade', 'ERRO')
        return
      }

      this.localTrainingsModalities.splice(index, 1)
      this.showModalDeleteLocalTrainingModality = false
    },

    onEditLocalTrainingModality(localTrainingModality) {
      this.localTrainingModalitySelectedIndex = localTrainingModality.index
      this.localTrainingModality = new LocalTrainingModality(localTrainingModality.item)
      this.onSelectModality()
      this.onSelectLocalTraining()
      this.showModalLocalTrainingModality = true
    },

    mergeItemList () {
      const isEditing = this.localTrainingModalitySelectedIndex != null

      if(isEditing) {
        this.localTrainingsModalities.splice(this.localTrainingModalitySelectedIndex, 1, this.localTrainingModality)
        return
      }

      this.localTrainingsModalities.push(this.localTrainingModality)
    }
  }
}
